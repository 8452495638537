import React, { useEffect } from "react";

// Polyfill for smooth scroll to fix safari, edge and IE
// @ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import { ThemeProvider } from "styled-components";

import { Banner } from "../components/Banner";
import { LandingPageNavigation } from "../components/LandingPageNavigation";
import SettingsContextHooksProvider from "../context/SettingsContextHooks";
import { GlobalStyle } from "../styles/globalStyle";
import "../styles/main.scss";
import { theme } from "../styles/theme";
import { StyledMain, StyledSite } from "./partials";

interface ILandingPageLayoutProps {
  children?: React.ReactNode;
  settings?: {};
  alternatives?: Array<string>;
}

const LandingPageLayout: React.FC<ILandingPageLayoutProps> = ({ children, settings = {}, alternatives = [] }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      smoothscroll.polyfill();
    }
  }, []);

  return (
    <>
      <SettingsContextHooksProvider settings={settings}>
        <ThemeProvider theme={theme}>
          <StyledMain>
            <GlobalStyle />
            <Banner />
            <StyledSite id="site" banner={settings?.content?.banner_notice ? true : false}>
              <LandingPageNavigation />
              {children}
            </StyledSite>
          </StyledMain>
        </ThemeProvider>
      </SettingsContextHooksProvider>
    </>
  );
};

export default LandingPageLayout;
