import React, { useContext, useEffect } from "react";
import LandingPageLayout from "../layouts/landingPageLayout";

import DynamicComponent from "../components";

import useStoryblok from "../lib/storyblok";

import PageContext from "../context/PageContext";

interface LPPageProps {
  pageContext: any;
  location: Location;
}

const LandingPageHook: React.FC<LPPageProps> = ({ pageContext, location }) => {
  const pageCtx = useContext(PageContext);

  useEffect(() => {
    pageCtx.set && pageCtx.set({ ...pageContext });
  }, [pageContext]);

  const { alternatives } = pageContext;
  let { story, settings } = pageContext;
  ({ story, settings } = useStoryblok(location, story, settings));

  if (typeof settings.content === "string") {
    settings.content = JSON.parse(settings.content);
  }

  if (typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  return (
    <LandingPageLayout
      settings={settings}
      alternatives={alternatives}
    >
      {story && (
        <DynamicComponent
          key={story.content._uid}
          content={story.content}
          blok={story.content}
          settings={settings}
          story={story}
          alternates={alternatives}
        />
      )}
    </LandingPageLayout>
  );
};

export default LandingPageHook;
