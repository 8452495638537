import styled, { css } from "styled-components";

export const StyledLandingPageNavigation = styled.header`
  position: relative;
  min-height: 100px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: block;
    width: 100%; /* Full width */
    min-height: 70px;
    height: auto;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
`;

export const StyledNavigationContainer = styled.nav`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.neutrals.white};
  height: auto;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 70px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 ${(props) => props.theme.spacing.three};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0;
  }
`;

export const StyledHeaderLogo = styled.a`
  position: relative;
  height: auto;
  width: auto;
  max-width: 150px;

  & img {
    height: 32px;
    object-fit: contain;
    object-position: left;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    & img {
      height: 24px;
    }
  }
`;

export const StyledNavigationInner = styled.div`
  position: relative;
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;

  max-width: ${(props) => props.theme.maxWidth};
  padding: ${(props) => props.theme.spacing.four} 0;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 20px ${(props) => props.theme.spacing.three};
    background: #ffffff;
  }
`;
