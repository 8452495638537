import React, { useContext } from "react";
import {
  StyledLandingPageNavigation,
  StyledNavigationContainer,
  StyledHeaderLogo,
  StyledNavigationInner,
} from "./partials";
import { SettingsContextHooks } from "../../context/SettingsContextHooks";
import { SbImage } from "hult-sb-ui";

export const LandingPageNavigation: React.FC = ({}) => {
  const { settings } = useContext(SettingsContextHooks);

  return (
    <StyledLandingPageNavigation>
      <StyledNavigationContainer>
        <StyledNavigationInner>
          <StyledHeaderLogo href={settings?.content?.logo_url?.url}>
            <SbImage
              image={settings?.content?.logo}
              resize="137x32"
              height="32px"
              width="137px"
            />
          </StyledHeaderLogo>
        </StyledNavigationInner>
      </StyledNavigationContainer>
    </StyledLandingPageNavigation>
  );
};
